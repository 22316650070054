import { Component, OnInit, ViewEncapsulation, DoCheck, KeyValueDiffers, KeyValueDiffer} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WOption } from '@wipo/w-angular/shared';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { DBInfoSolrResponse } from '../../interfaces';
import { MechanicsService } from '../../_services/mechanics.service';
import { QueryParamsService } from '../../_services/queryParams.service';
import { SearchService } from '../../_services/search.service';
import { PreferencesService } from '../../_services/preferences.service';
import { asStructure2bricks, bricks2AsStructure, canSearch, deepClone, generateId } from '../../utils';
import { OptionsListService } from '../../_services/options-list.service';

@Component({
	selector: 'page-similarlogo',
	templateUrl: './page-similarlogo.component.html',
	styleUrls: ['./page-similarlogo.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PageSimilarLogoComponent implements OnInit, DoCheck {

	public strategies: WOption[] = []

	public bricks;
	public structure
	public show_info
	differ: KeyValueDiffer<string, any>;
	public ev1 = false
	
	get captchaURL(): string {
		return this.ms.environment.backendUrl + '/captcha?t=' +  localStorage.getItem("gbd.hashSearches")
	}


	async onVerify(ev: any, place_holder_name) {
		if (ev.type === 'verified') {
			this[place_holder_name] = true
		}
	}	


	constructor(private ar: ActivatedRoute,
		public ms: MechanicsService,
		public ss: SearchService,
		public qs: QueryParamsService,
		public ps: PreferencesService,
		public ols: OptionsListService,
		private differs: KeyValueDiffers) {


		const l = `page-similarName constructor - `

		this.ms.setEndpoint("similarlogo")
		this.bricks = this.defaultBricks;
		this.differ = this.differs.find({bricks:this.bricks}).create();

	}

	ngDoCheck() {
		const change = this.differ.diff(this.bricks);
		if (change) {
			this.structure.bricks= bricks2AsStructure(this.bricks, this.preprocessBricks).bricks
		}
	  }

	  

	ngOnInit(): void {

		const l = `pageSimilarLogo ngOnInit() - `

		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC

		// Mona : if coming back to this page from results page, remove facets --> Jer : But how do you know you're coming back from the search page and not just reloading the page
		this.qs.keepOnlySearchParams();
		this.buildStuff()
	}

	ngOnDestroy() {
		this.ms.unsetEndpoint()
		this.ms.unsetSearchError()
	}

	get defaultBricks() {
		return {
			// Image strategy and bases64 are directly in the queryParams, not in asStructure. They're not search fields, they're sort options
			niceClass: [],
			viennaClass: [],
			usDesignClass: [],
			office: [],
			markFeature: [],
			applicant: "",
			designation: [],
			goodsServices: "",
			_shadowUpdate: 0

		}
	}


	buildStuff() {

		const l = `page-similarLogo buildStuff() - `

		this.strategies = [
			{ value: "concept", label: "" },
			{ value: "shape", label: "" },
			{ value: "color", label: "" },
			{ value: "composite", label: "" },
		].map(obj => {
			obj.label = this.ms.translate(`page_similarlogo.${obj.value}`);
			return obj
		})

		this.buildBricksFromQP()
		this.structure = bricks2AsStructure(this.bricks,  this.preprocessBricks);

	}

	get parent(){
		return this
	}


	preprocessBricks(orig_bricks){
		let bricks = deepClone(orig_bricks);

		if (bricks.usDesignClass.length > 0 && bricks.viennaClass.length > 0){
			bricks.classes_extra = {
				"_id": generateId(),
				"boolean": "OR",
				"bricks": [
				  {
					"_id": generateId(),
					"key": "usDesignClass",
					"value": bricks["usDesignClass"]
				  },
				  {
					"_id": generateId(),
					"key": "viennaClass",
					"value": bricks["viennaClass"]
				  }
				]
			  }
			delete bricks.usDesignClass
			delete bricks.viennaClass

		}

		if(bricks.markFeature.length > 0){
			bricks.mark_extra =
				{
				  "_id": generateId(),
				  "key": "markFeature",
				  "value": bricks.markFeature
				}
		}
		if (bricks.office.length > 0 && bricks.designation.length > 0){
			bricks.countires_extra = {
				"_id": generateId(),
				"boolean": "OR",
				"bricks": [
				  {
					"_id": generateId(),
					"key": "office",
					"value": bricks["office"]
				  },
				  {
					"_id": generateId(),
					"key": "designation",
					"value": bricks["designation"]
				  }
				]
			  }
			delete bricks.office
			delete bricks.designation

		}
		delete bricks._shadowUpdate
		delete bricks.markFeature
		return bricks
	}
	
	buildBricksFromQP() {
		const l = `page-similarName buildBricksFromQP() - `
		const qpAsStructureString: string = this.qs.getQP("asStructure")
		let asStructure
		try{
			asStructure = JSON.parse(qpAsStructureString)
		}
		catch(err){
			return
		}
		// PostProcessing 
		let office = []
		let designation = []
		let usDesignClass =[]
		let viennaClass =[]

		let to_pop: number[] = []

		for (let idx in asStructure.bricks){
			let tmp_brick = asStructure.bricks[idx]
			if(tmp_brick['bricks'] == undefined)
				continue
			tmp_brick = tmp_brick.bricks
			for(let idx2 in tmp_brick){
				if (tmp_brick[idx2].key == "designation")
					designation = tmp_brick[idx2].value
				if (tmp_brick[idx2].key == "office")
					office = tmp_brick[idx2].value
				if (tmp_brick[idx2].key == "viennaClass")
					viennaClass = tmp_brick[idx2].value
				if (tmp_brick[idx2].key == "usDesignClass")
					usDesignClass = tmp_brick[idx2].value
			}
			to_pop.push(Number(idx))
		}
		for (let idx in to_pop){
			asStructure.bricks.splice((to_pop[idx]-Number(idx)),1)
		}

		// console.log(`${l}type=${typeof qpAsStructure } - qpAsStructure = `, qpAsStructure)
		if (qpAsStructureString?.length && qpAsStructureString?.length > 2) {
			this.bricks = Object.assign(this.bricks, asStructure2bricks(asStructure))
		}
		
		if(office.length > 0)
			this.bricks.office = office
		if(designation.length > 0)
			this.bricks.designation = designation
		if(usDesignClass.length > 0)
			this.bricks.usDesignClass = usDesignClass
		if(viennaClass.length > 0)
			this.bricks.viennaClass = viennaClass
	}

	get canSearch(): boolean {

		// either image is selected or niceClass or viennaClass or USDesignClass

		if (this.ms.bases64?.length && this.ms.editingImageIndex === null) {
			return true
		}

		return canSearch(this.bricks.niceClass) || canSearch(this.bricks.viennaClass) || canSearch(this.bricks.usDesignClass) || canSearch(this.bricks.applicant) || canSearch(this.bricks.office) || canSearch(this.bricks.designation) || canSearch(this.bricks.niceClass)  || canSearch(this.bricks.goodsServices) || canSearch(this.bricks.markFeature)

	}

	// Updates the URL accordingly without reloading the page.
	// Changing the query params in the URL will trigger a subscription in HOCSearchComponent,
	// which in turn will automatically call SearchService.search() and refresh the results.
	async search() {
		const l: string = `PageSimilarLogo search() - `
		if (!this.canSearch) return;

		this.qs.setQP("asStructure", JSON.stringify(bricks2AsStructure(this.bricks,  this.preprocessBricks)));
		this.qs.setQP("fg", "_void_")
		const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: 'results', caller: l })
		this.ps.setPref("sort", "score desc"); // Christophe wants to reset the sort order to the default every time we search for a logo
		await this.qs.queryParamsObjectToUrl(route)
	}

	doReset() {

		const l = `page-similarLogo doReset - `

		this.ms.editingImageIndex = null
		this.ms.bases64 = []
		this.ms.bases64resized = []
		sessionStorage.setItem("gbd.ms.bases64", null)
		
		this.bricks = this.defaultBricks;
	}

	// -----------------------
	// autocompletion on names
	// -----------------------


	//- Suggestions have been turned off for now, using a simple text field
	/*
		onSuggestSelect(value, what: string) {
			const l: string = `PageExploreComponent onSuggestSelect() - `

			// remove the highlight tag <b></b>
			value = value.replace(/<[^>]*>?/gm, '');

			this.qs.queryParams[this.ms.endpoint][what] = value
		}
	*/

	onModelChange($event, which: string) { // classifications - which="viennaClass", "usDesignClass"

		const l = `onModelChange() - `

		// I admit my ignorance about how to add a [(ngModel)] to a custom component (classification-suggest in this case), so I make my component emit a (modelChange) event, I catch it here, and I set it manually

		// console.log(`${l}$event = `, $event) // $event = "<b>05</b>.03.<b>05</b> - Leaves of chestnut trees"

		this.qs.setQP(which, $event);
	}


	onSuggestSelect($event, which: string) { // classifications - which="viennaClass", "usDesignClass"

		const l = `onSuggestSelect() - `

		// console.log(`${l}$event = `, $event) // $event = "<b>05</b>.03.<b>05</b> - Leaves of chestnut trees"

		let value = $event;

		// Removing the highlight tag <b></b>
		value = value.replace(/<[^>]*>/gm, ''); // "02.03.07 - Women wearing an evening dress"
		let number = value.split(" - ")[0]; // Extracting the number at the beginning

		// console.log(`${l}QP*=`, deepClone(this.qs.getQP("*")))
		// console.log(`${l}getting QP '${which}'`)

		let fullInputValue = this.qs.getQP(which) || "";

		// console.log(`${l}fullInputValue 162 = '${fullInputValue}'`)

		// Removing the last thing the user has typed ("5" or " wom") to replace it with the classification number
		fullInputValue = fullInputValue.replace(/ ?[^ ]+$/, "")
		fullInputValue += ` ${number}`;
		// Cleanup
		fullInputValue = fullInputValue.trim().replace(/ +/g, " ");

		// console.log(`${l}setting pq.${which} = ${fullInputValue}`)

		this.qs.setQP(which, fullInputValue);

	}

	doSuggestFocus($event) {
		$event.target.select()
	}


}
