import { Component, OnInit, ViewEncapsulation, DoCheck, KeyValueDiffers, KeyValueDiffer} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WOption } from '@wipo/w-angular/shared';

import { SearchService } from 'src/app/_services/search.service';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { asStructure2bricks, bricks2AsStructure, canSearch, deepClone, generateId } from 'src/app/utils';
import { OptionsListService } from 'src/app/_services/options-list.service';

@Component({
	selector: 'page-similarname',
	templateUrl: './page-similarname.component.html',
	styleUrls: ['./page-similarname.component.css'],
	encapsulation: ViewEncapsulation.None,
})

export class PageSimilarNameComponent implements OnInit, DoCheck {

	public strategies: WOption[] = []

	public bricks;
	public structure
	public show_info = false;
	differ: KeyValueDiffer<string, any>;
	public recordsCount: string = null
	public dataSourcesCount: string = null
	public ev1 = false
	
	constructor(private ar: ActivatedRoute,
		public ss: SearchService,
		public ms: MechanicsService,
		public qs: QueryParamsService,
		public ols: OptionsListService,
		private differs: KeyValueDiffers) {

		const l = `page-similarName constructor - `

		this.ms.setEndpoint("similarname")
		this.ms.setOffice(this.ar.snapshot.params.office) 
		this.differ = this.differs.find({bricks:this.bricks}).create();
		// this.qs.resetQP("similarname");
	}

	ngDoCheck() {
		let change = this.differ.diff(this.bricks);
		if (change) {
			this.structure.bricks= bricks2AsStructure(this.bricks, this.preprocessBricks).bricks
		}
	  }

	async ngOnInit(): Promise<void> {

		const l = `pageSimilarName ngOnInit() - `

		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC
		// Mona : if coming back to this page from results page, remove facets --> Jer : But how do you know you're coming back from the search page and not just reloading the page
		this.qs.keepOnlySearchParams();

		this.buildStuff()
		this.ms.show_news = true
	}


	ngOnDestroy() {
		this.ms.unsetEndpoint()
		this.ms.unsetSearchError()
	}

	
	get captchaURL(): string {
		return this.ms.environment.backendUrl + '/captcha?t=' +  localStorage.getItem("gbd.hashSearches")
	}


	async onVerify(ev: any, place_holder_name) {
		if (ev.type === 'verified') {
			this[place_holder_name] = true
		}
	}	

	get defaultBricks() {
		return {
			brandName: "",
			brandNameStrategy: "Simple", // <-- This is not a search field. It must not be sent as a separate tkObject by the backend, but must be used to specify which strategy to use for the brandName field, then disappear
			applicant: "",
			office: [],
			number:"",				
			designation: [],
			niceClass: [],
			goodsServices: "",
			_shadowUpdate: 0
			
		}
	}

	preprocessBricks(orig_bricks){
		let bricks = deepClone(orig_bricks);
		if(bricks.number){
			bricks.number_extra = 
			{
				"_id": generateId(),
				"boolean": "OR",
				"bricks": [
				  {
					"_id": generateId(),
					"key": "appNum",
					"value": bricks["number"]
				  },
				  {
					"_id": generateId(),
					"key": "regNum",
					"value": bricks["number"]
				  }
				]
			  }
			}
		else
			delete bricks.number_extra
		delete bricks.number
		
		if (bricks.office.length > 0 && bricks.designation.length > 0){
			bricks.countires_extra = {
				"_id": generateId(),
				"boolean": "OR",
				"bricks": [
				  {
					"_id": generateId(),
					"key": "office",
					"value": bricks["office"]
				  },
				  {
					"_id": generateId(),
					"key": "designation",
					"value": bricks["designation"]
				  }
				]
			  }
			delete bricks.office
			delete bricks.designation
		}

		delete bricks._shadowUpdate
		return bricks
	}

	buildStuff() {

		const l = `page-similarName buildStuff() - `

		this.bricks = this.defaultBricks;
		this.strategies = [
			{ value: "Simple", label: "" }, 
			{ value: "Terms", label: "" },
			{ value: "Fuzzy", label: "" },
			{ value: "Phonetic", label: "" },
			// { value: "Embedded", label: "" }, --> Doesn't work, removing it for now
			{ value: "Stemming", label: "" },
		]
	
		this.strategies.map(obj => {
			obj.label = this.ms.translate(`page_similarname.${obj.value}`);
			return obj
		})

		this.buildBricksFromQP()
		this.structure = bricks2AsStructure(this.bricks, this.preprocessBricks);

	}
	get windowWidth(): number {
		return window.innerWidth
	}

	get parent(){
		return this
	}

	buildBricksFromQP() {
		const l = `page-similarName buildBricksFromQP() - `
		const qpAsStructureString: string = this.qs.getQP("asStructure")
		let asStructure
		try{
			asStructure = JSON.parse(qpAsStructureString)
		}
		catch(err){
			return
		}
		// PostProcessing 
		let number = ''
		let office = []
		let designation = []

		let to_pop: number[] = []

		for (let idx in asStructure.bricks){
			let tmp_brick = asStructure.bricks[idx]
			if(tmp_brick['bricks'] == undefined)
				continue
			tmp_brick = tmp_brick.bricks
			for(let idx2 in tmp_brick){
				if (tmp_brick[idx2].key == "appNum" || tmp_brick[idx2].key == "regNum" )
					number = tmp_brick[idx2].value
				if (tmp_brick[idx2].key == "designation")
					designation = tmp_brick[idx2].value
				if (tmp_brick[idx2].key == "office")
					office = tmp_brick[idx2].value
			}
			to_pop.push(Number(idx))
		}
		for (let idx in to_pop){
			asStructure.bricks.splice((to_pop[idx]-Number(idx)),1)
		}

		// console.log(`${l}type=${typeof qpAsStructure } - qpAsStructure = `, qpAsStructure)
		if (qpAsStructureString?.length && qpAsStructureString?.length > 2) {
			this.bricks = Object.assign(this.bricks, asStructure2bricks(asStructure))
		}
		
		this.bricks.number = number
		if(office.length > 0)
			this.bricks.office = office
		if(designation.length > 0)
			this.bricks.designation = designation
	}

	get canSearch(): boolean {
		return canSearch(this.bricks.brandName) || canSearch(this.bricks.applicant) || canSearch(this.bricks.number) || canSearch(this.bricks.office) || canSearch(this.bricks.designation) || canSearch(this.bricks.niceClass)  || canSearch(this.bricks.goodsServices)
	}

	async search() {

		const l: string = `PageSimilarName search() - `

		if (!this.canSearch) return;

		const asStructure = bricks2AsStructure(this.bricks,  this.preprocessBricks);

		// this.qs.setQP("asStructure", JSON.stringify(asStructure)) // Why the hell was I stringifying the asStructure??
		this.qs.setQP("asStructure", asStructure)
		this.qs.setQP("fg", "_void_")

		// console.log(`01 - ${l}HAve set QP.asStructure =`, asStructure)

		const route: string = this.ms.makeRoute({ path: this.ms.endpoint, subpath: 'results', caller: l })

		// Updates the URL accordingly without reloading the page.
		// Changing the query params in the URL will trigger a subscription in HOCSearchComponent ,
		// which in turn will automatically call SearchService.search() and refresh the results.
		await this.qs.queryParamsObjectToUrl(route)
	}

	doReset() {
		const l = `page-similarname doReset() - `
		this.bricks = this.defaultBricks;
	}

}
