import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { PreferencesService } from 'src/app/_services/preferences.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { SearchService } from 'src/app/_services/search.service';
import { prevEndpoint } from 'src/app/utils';

@Component({
	selector: 'search-results-menu',
	templateUrl: './search-results-menu.component.html',
	styleUrls: ['./search-results-menu.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class SearchResultsMenuComponent implements OnInit {

	@Input() statsMode:any

	// emit an event to the parent when a menu choice is clicked
	@Output() menuChoiceEvent = new EventEmitter<any>();
	@Output() menuActionEvent = new EventEmitter<any>();

	// stores the name of the menu showing the options in a tooltip
	public shownTooltip: string;


	public menuItemsResults: any[] = [
		{
			name: "layout",
			options: ["list", "grid", "gallery"]
		},
		{
			name: "sort",
			options: [
				"score desc",
				"score asc",
				"applicationDate desc",
				"applicationDate asc",
				"st13 desc",
				"st13 asc",
				// "registrationNumber desc",
				// "registrationNumber asc",
			]
		},
		{
			name: "rows",
			options: ["30", "60", "120"]
		},
		{
			name: "export",
			actions: ["PDF", "XLSX"]
		}
		// {
		//	 name: "save"
		// },
		// {
		//	 name: "watch"
		// }
	]

	public menuItems: any[] = []
	public menuItemsExplore: any[] = [
		{
			name: "view_graphs",
		},{
			name: "view_tables",
		},
		
	]

	public disableResults = false

	constructor(public qs:QueryParamsService,
		public ps:PreferencesService,
		public ms: MechanicsService,
		public ss: SearchService) { 

			// console.log(`search-results-menu - this.statsMode = `, this.statsMode)
			if(this.ms.endpoint != 'explore'){
				this.menuItems = this.menuItemsResults
			}
			else{
				this.menuItems = this.menuItemsExplore

			}
			if(window.outerWidth <= 800){
				this.menuItems.splice(0, 1);
				this.menuItems.splice(1, 1);
				ps.setPref('layout', 'list')
			}
	}


	ngOnInit(): void {
		this.disableResults = prevEndpoint() == null
	}

	ngOnDestroy(): void { }

	onTooltipTriggerClicked(menu: any): void {

		const l = `onMenuClicked - `

		// console.log(`${l}`)

		// toggle options visibility
		if (menu.options || menu.actions) {
			this.shownTooltip = this.shownTooltip === menu.name
				? ''
				: menu.name
		}
		// no options => emit event
		else {
			this.menuActionEvent.emit({ name: menu.name });
		}
	}

	onMenuOptionClicked(name: string, value: string): void {

		const l = `SearchResultsMenuComponent clickMenuOption`

		const toEmit = { name, value }

		// console.log(`${l}toEmit=`, toEmit)

		this.qs

		this.menuChoiceEvent.emit(toEmit);
	}

	clickMenuAction(name: string, value: string): void {
		this.menuActionEvent.emit({ name, value });
	}

	isChecked(menuName:string, value:string): boolean{

		const l = `isChecked`

		const isChecked = (this.qs.getQP(menuName) || this.ps.getPref(menuName)) == value

		return isChecked
	}

}
